import React, { useEffect, useState } from "react";
import NavBar from "../../NavBar/NavBar";
import { reactLocalStorage } from "reactjs-localstorage";
import { Redirect, useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button, InputGroup, FormControl } from 'react-bootstrap'
import { FaUser, FaLock } from "react-icons/fa";
import "./../../Auth/Auth.css"
import Login from "./Login";

const VendorLogin = () => {
  //local storage data read START


  //local storage data read END

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleClick(e) {
    login();
    e.preventDefault();
  }

  const history = useHistory();

  const login = async () => {
    try {
      const link =
        "https://backend.amaderservice.com/api/Vendor_Login?email=" +
        email +
        "&password=" +
        password;

      const data = await fetch(link);
      const item = await data.json();
      console.log(item);

      if (item.length !== 0) {
        //alert("from vendor login Page")
        reactLocalStorage.setObject("vendorInfo", item);

        reactLocalStorage.set("isLoggedInVendor", true);

        history.push(`/vendor/account`);
      } else {
        alert("Login Error!");
      }
    } catch (error) {
      alert("Login Error!");
    }
  };

  return (
    <div>
      <div className="mb-5">
        <NavBar></NavBar>
      </div> <br /> <br />

      <Container>
        <Row>
          <Col style={{ textAlign: "center" }} sm={7}>
            <img style={{ width: "80%" }} src="img/AnotherPic/vendorlogin.png" alt="vendorlogin.png" />
          </Col>
          <Col className="venlogP" sm={5}> <br /> <br /> <br />
            <div className="venlog"> <br />
              <h1>Login</h1> <br />

              <form action="" onSubmit={handleClick}>
                <div>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="VenInput" id="inputGroup-sizing-default"><FaUser /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl className="VenInpuForm"
                      class="form-control"
                      id="floatingInput"
                      placeholder="Enter email..."
                      type="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      required
                    />
                  </InputGroup>


                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="VenInput" id="inputGroup-sizing-default"><FaLock /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl className="VenInpuForm"
                      placeholder="Enter password..."
                      class="form-control"
                      id="floatingPassword"
                      type="password"
                      value={password}
                      maxLength={16}
                      onChange={(event) => setPassword(event.target.value)}
                      required
                    />
                  </InputGroup>
                  <br />
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me" />
                      </Form.Group>
                    </Col>

                    <Col sm={6}>
                    <p onClick={()=> history.push(`/signup/vendor`)} style={{cursor:"pointer"}}>
                      Create Account
                    </p>
                    
                    </Col>
                  </Row>


                  
                  <div style={{ textAlign: "center" }} > <br />
                  <Login></Login>
                    <Button variant="warning" type="submit" value="Login" >Login</Button>{' '}
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container> <br /> <br />
    </div>
  );
};

export default VendorLogin;
