import { useState } from "react";
import Modal from 'react-bootstrap/Modal'
import { Button, Form } from 'react-bootstrap'


function Login() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <p style={{cursor: "pointer" }} onClick={handleShow}>  Forget password</p>
            
            <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h4 style={{ textAlign: "center", }}>Forget Password?</h4>
                    <h6 style={{ textAlign: "center" }}>Don't worry!</h6> <br />
                    <Form style={{ padding: "10px" }}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control className="placeStyle" type="email" placeholder="Enter email" /> <br />
                            <Form.Text className="text-muted">
                                Please check your email. Never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicCheckbox">
                        </Form.Group> <br />
                        <div style={{ textAlign: "center" }} > <br />
                            <Button variant="success" type="submit" >Submit</Button> 
                        </div><br/>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Login
